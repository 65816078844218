@import url('https://p.typekit.net/p.css?s=1&k=fhh6rzt&ht=tk&f=17004.17005.17006.22621&a=22538057&app=typekit&e=css');
@import 'react-phone-number-input/style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'soleil';
    src: url('https://use.typekit.net/af/da0a6a/00000000000000003b9aef7a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/da0a6a/00000000000000003b9aef7a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff'),
      url('https://use.typekit.net/af/da0a6a/00000000000000003b9aef7a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'soleil';
    src: url('https://use.typekit.net/af/2e4bb3/00000000000000003b9aef7e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/2e4bb3/00000000000000003b9aef7e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/2e4bb3/00000000000000003b9aef7e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'soleil';
    src: url('https://use.typekit.net/af/273d01/00000000000000003b9aef81/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/273d01/00000000000000003b9aef81/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('woff'),
      url('https://use.typekit.net/af/273d01/00000000000000003b9aef81/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'soleil';
    src: url('https://use.typekit.net/af/bdec83/00000000000000003b9aef7b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/bdec83/00000000000000003b9aef7b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
        format('woff'),
      url('https://use.typekit.net/af/bdec83/00000000000000003b9aef7b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 300;
  }
}

input:-webkit-autofill {
  border: 1px solid #e2e8f0;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  color: rgb(74, 85, 104);
}

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset, 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  outline: none;
  color: rgb(74, 85, 104);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'soleil', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  position: relative;
}

button:focus {
  outline: none;
  box-shadow: none;
}

*:focus {
  outline: 0;
}
a:focus {
  @apply outline-black;
}
